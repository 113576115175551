import React, { useRef, useEffect, useReducer, useContext } from "react";

import AuthContext from '../../../../../contexts/auth/auth-context';
import ExplorerContextReducer from "./explorer-context-reducer";
import ExplorerContext from "./index";
import {
    SET_DIRECTORIES,
    SET_PARENT_CONTENT,
    SET_PARENT_SELECTION,
    SET_PARENT_FILTER,
    SET_USERS,
    SET_ITEM_SELECTED_USERS,
    OPEN_ITEM_PERMISSIONS,
    OPEN_ITEM_PROHIBITIONS,
    ON_ITEM_USERS_CLOSE
} from "./explorer-context-constants";
import useExplorerContext from "./use-explorer-context";

const initialState = {
    directories: [],
    rows: [],
    directory: null,
    content: null,
    parentSelection: '',
    selectedParent: null,
    treeFilter: '',
    row: null,
    users: [],
    openUsers: false,
    type: '',
    selectedUsers: [],
    openItemPermissions: () => { },
    openItemProhibitions: () => { },
    onItemUsersClose: () => { },
    onItemUsersSelected: () => { },
};

const ExplorerContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(ExplorerContextReducer, initialState);
    const { isAuthenticated, userId } = useContext(AuthContext);
    const {
        getDirectories,
        getDirectoryContent,
        getUsers,
        getInitialData,
        updatePermissions,
        updateProhibitions,
    } = useExplorerContext();

    const refLoading = useRef();

    useEffect(() => {
        console.log(`ExplorerContextProvider.useEffect, isAuthenticated: ${isAuthenticated}`);

        if (!isAuthenticated && state.directories) {
            setDirectories(null);
        }

        if (isAuthenticated && !refLoading.current && !state.directories?.length) {
            refLoading.current = true;
            console.log('ExplorerContextProvider.useEffect.LoadingData');
            getInitialData(setDirectories, setUsers).finally(() => refLoading.current = false);
        }
        // eslint-disable-next-line
    }, [isAuthenticated, userId, state.row]);

    const reloadTreeData = () => getDirectories().then((x) => setDirectories(x));

    const setDirectories = (complex) => dispatch({ type: SET_DIRECTORIES, payload: complex });
    const setParentContent = (data) => dispatch({ type: SET_PARENT_CONTENT, payload: data });
    const openItemPermissions = ({ openUsers, type, row }) => dispatch({ type: OPEN_ITEM_PERMISSIONS, payload: { openUsers, type, row } });
    const openItemProhibitions = ({ openUsers, type, row }) => dispatch({ type: OPEN_ITEM_PROHIBITIONS, payload: { openUsers, type, row } });
    const onItemUsersClose = () => dispatch({ type: ON_ITEM_USERS_CLOSE, payload: { openUsers: false, type: '', row: null } });
    const onItemUsersSelected = (checked) => dispatch({ type: SET_ITEM_SELECTED_USERS, payload: checked });
    const setUsers = (data) => dispatch({ type: SET_USERS, payload: data });
    const refreshUsers = () => isAuthenticated && getUsers(setUsers);
    const changeItemPermissions = async () => await updatePermissions(state.row, state.selectedUsers).then(() => onItemUsersClose());
    const changeItemProhibitions = async () => await updateProhibitions(state.row, state.selectedUsers).then(() => onItemUsersClose());
    const setParentSelection = (row) => {
        dispatch({ type: SET_PARENT_SELECTION, payload: row });
        if (!row) {
            setParentContent(null);
        } else {
            getDirectoryContent(row.id).then(data => setParentContent(data));
        }
    };

    const setParentFilter = (treeFilter) => dispatch({ type: SET_PARENT_FILTER, payload: treeFilter });

    return (
        <ExplorerContext.Provider
            value={{
                setParentSelection,
                setParentFilter,
                parentSelection: state.parentSelection,
                selectedParent: state.selectedParent,
                treeFilter: state.treeFilter,
                directories: state.directories,
                rows: state.rows,
                row: state.row,
                content: state.content,
                reloadTreeData,
                id: state.id,
                users: state.users,
                openUsers: state.openUsers,
                type: state.type,
                openItemPermissions,
                openItemProhibitions,
                onItemUsersClose,
                changeItemPermissions,
                changeItemProhibitions,
                selectedUsers: state.selectedUsers,
                onItemUsersSelected,
                refreshUsers,
            }}
        >
            {children}
        </ExplorerContext.Provider>
    );
};

export default ExplorerContextProvider;
