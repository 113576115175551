import { StorageKeys } from "../../../constants";
import useTranslationHelpers from "./use-translation-helpers";

const useTranslationsStored = () => {

    const storedLanguage = localStorage.getItem(StorageKeys.Language);
    const { createLangTranslations } = useTranslationHelpers();

    const findStorageTranslations = (apiVersion) => {
        const storedJson = localStorage.getItem(StorageKeys.Translations);
        if (!storedJson || storedJson === "undefined" || storedJson === "null") {
            return null;
        }

        const storedValue = JSON.parse(storedJson) || null;
        if (!storedValue ||
            !Object.keys(storedValue || {}).length ||
            (apiVersion && apiVersion !== storedValue.apiVersion)) {
            return null;
        }
        return storedValue;
    }

    const storedTranslations = findStorageTranslations();

    const createTranslations = () => {
        if (storedLanguage && storedTranslations) {
            const { culture, languageKeys, parsedTranslations } = createLangTranslations(storedLanguage, storedTranslations);
            return {
                storedVersion: localStorage.getItem(StorageKeys.ApiVersion),
                storedLanguage: culture,
                storedLanguageKeys: languageKeys,
                storedLangTranslations: parsedTranslations,
                storedTranslations: storedTranslations,
            }
        };

        return {
            storedVersion: '',
            storedLanguage,
            storedLanguageKeys: null,
            storedLangTranslations: null,
            storedTranslations: null,
        };
    };

    return ({ ...createTranslations() });
};

export default useTranslationsStored;