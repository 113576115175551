import React, { useState, useEffect, useContext, Fragment } from 'react';
import { Box, useTheme } from '@mui/system';
import './loader.css';
import TranslationsContext from '../../../../contexts/translation/translation-context';

let timer = null;

const Loader = ({ show, loading }) => {
    const activate = show || loading;
    const { t } = useContext(TranslationsContext);
    const [active, setActive] = useState(false);
    const theme = useTheme();
    const changeState = (visible, isMounted) => {
        if (isMounted) {
            console.log("Loader show: " + visible)
            setActive(visible);
        }
    };

    useEffect(() => {
        let mounted = true;
        if (activate) {
            timer = setTimeout(() => changeState(true, mounted), 600);
        } else {
            clearTimeout(timer);
            changeState(false, active && mounted);
        }
        return () => mounted = false;
    }, [activate, active]);

    const reloadPage = () => window.location.reload();

    return (active ?
        <Fragment>
            <Box sx={{ zIndex: 999, backgroundColor: theme.palette.text.primary }} className="loader"></Box>
            <Box
                sx={{ zIndex: 99999999 }}
                title={t("ClickToReload")}
                className="loaderImg loaderCircle"
                onClick={reloadPage}>
            </Box>
        </Fragment> : null);
};

export default Loader;