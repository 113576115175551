
import axios from 'axios'
import { StorageKeys } from '../../../constants';


const useApi = (contentType) => {

    const CancelToken = axios.CancelToken;
    const cancelSource = CancelToken.source();
    const isCanceled = axios.isCancel;

    const api = axios.create({
        cancelToken: cancelSource.token,
        headers: {
            "Content-Type": contentType || "application/json",
            "SID": localStorage.getItem(StorageKeys.Sid),
            "X-Lang": localStorage.getItem(StorageKeys.Language)
        },
    });

    const getData = resource => {
        return api.get(resource);
    };

    const postData = (resource, payload) => {
        return api.post(resource, payload);
    };

    const putData = (resource, payload) => {
        return api.put(resource, payload);
    };

    const deleteData = resource => {
        return api.delete(resource);
    };

    const getErrorMessageFromData = (error, errorData) => Array.isArray(errorData) ? errorData.join(". ") : (error.message || errorData);

    const getErrorMessage = (error) => {

        const errorData = error.response && error.response.data;

        if (errorData) {
            const isCamelCase = errorData.statusCode || errorData.status;
            if (isCamelCase) {
                return `Status: ${errorData.statusCode || errorData.status}, ${errorData.title} ${errorData.detail ? (', ' + errorData.detail) : ''}`;
            } else {
                return `Status: ${errorData.StatusCode || errorData.Status}, ${errorData.Title} ${errorData.Detail ? (', ' + errorData.detail) : ''}`;
            }
        }

        return JSON.stringify(getErrorMessageFromData(error));
    };

    return {
        api,
        cancelSource,
        isCanceled,
        getData,
        postData,
        putData,
        deleteData,
        getError: getErrorMessage,
    };
};

export default useApi;
