import React, { useReducer, useRef, useEffect } from "react";
import useApi from "../../scopes/common/usings/use-api";
import {
    SET_INITIAL,
    CHANGE_LANGUAGE,
    CHANGE_VERSION
} from "./translation-const";

import useTranslationsStored from "../../scopes/common/usings/use-translations-stored";
import useTranslationHelpers from "../../scopes/common/usings/use-translation-helpers";
import TranslationReducer from "./translation-reducer";
import { StorageKeys, DefaultLang } from "../../constants";
import { enGB, enUS, lt, uk } from 'date-fns/locale';
import TranslationsContext from "./translation-context";

export const getLocale = (culture) => {
    switch (culture) {
        case 'lt':
            return lt;
        case 'uk':
            return uk;
        default:
            return enGB;
    }
};

const TranslationsProvider = ({ children }) => {

    const dateFnOptions = { format: { formatStr: "yyyy-MM-dd" } };
    lt.options = dateFnOptions;
    uk.options = dateFnOptions;
    enGB.options = dateFnOptions;
    enUS.options = dateFnOptions;

    const { getData, getError } = useApi();
    const { createLangTranslations } = useTranslationHelpers();

    const { storedVersion: apiVersion,
        storedLanguage: language,
        storedLanguageKeys: languageKeys,
        storedLangTranslations: langTranslations,
        storedTranslations: translations } = useTranslationsStored();

    const [state, dispatch] = useReducer(TranslationReducer,
        {
            apiVersion,
            language,
            languageKeys,
            langTranslations,
            translations,
            locale: getLocale(language),
        });

    const translationsRef = useRef();

    const initTranslations = async () => await getData(`/api/common/localization?version=${state?.apiVersion || ''}`)
        .then((response) => {
            const currentLang = (translationsRef.current || state)?.language ?? DefaultLang;
            if (!response.data) {
                return false;
            }

            if (state.translations && response.data.translations === JSON.stringify(state.translations)) {
                return false;
            }

            const translations = JSON.parse(response.data.translations);
            const { culture, languageKeys, parsedTranslations } = createLangTranslations(currentLang, translations);

            if (JSON.stringify((translationsRef.current || state)?.langTranslations) !== JSON.stringify(parsedTranslations)) {
                return onTranslationsCreated({ ...state, locale: getLocale(culture), apiVersion: response.data.apiVersion, language: culture, languageKeys, langTranslations: parsedTranslations, translations: translations });
            }


            return false;

        }).catch((ex) => {
            console.error(getError(ex));
            return false;
        });

    const changeTranslations = (lang) => {
        const { culture, languageKeys, parsedTranslations } = createLangTranslations(lang, state.translations);
        return onTranslationsCreated({ ...state, language: culture, locale: getLocale(lang), languageKeys, langTranslations: parsedTranslations });
    };

    const onTranslationsCreated = (resources) => {
        resources.translations && localStorage.setItem(StorageKeys.Translations, JSON.stringify(resources.translations));
        localStorage.setItem(StorageKeys.ApiVersion, resources.apiVersion);
        localStorage.setItem(StorageKeys.Language, resources.language);
        translationsRef.current = resources;
        return resources;
    };

    useEffect(() => {
        initTranslations()
            .then(data => {
                if (data && data.apiVersion && data.language) {
                    dispatch({
                        type: SET_INITIAL,
                        payload: data
                    });
                }
            });
        // eslint-disable-next-line
    }, [])

    const changeLanguage = async (lang) => {
        localStorage.setItem(StorageKeys.Language, lang);
        const newState = changeTranslations(lang);
        if (newState) {
            dispatch({
                type: CHANGE_LANGUAGE,
                payload: newState,
            });
        }
    }

    const changeApiVersion = async (version) => {
        if (version !== state.apiVersion) {
            localStorage.setItem(StorageKeys.ApiVersion, version);
            const newState = await initTranslations();

            if (newState) {
                dispatch({
                    type: CHANGE_VERSION,
                    payload: newState,
                });
            }
        }
    }

    const translateKey = (key) => {
        const t = (translationsRef.current || state).langTranslations;
        return (t && t[key]) || key;
    }

    return (
        <TranslationsContext.Provider value={{
            ...state,
            changeLanguage,
            changeApiVersion,
            t: translateKey
        }} >
            {children}
        </TranslationsContext.Provider>
    );
};

export default TranslationsProvider;
