import { getLocale } from './translation-provider';

import {
    SET_INITIAL,
    CHANGE_LANGUAGE,
    CHANGE_VERSION
} from "./translation-const";

const TranslationReducer = (state, action) => {
    switch (action.type) {
        case SET_INITIAL:
        case CHANGE_VERSION:
        case CHANGE_LANGUAGE: {
            console.log(`TRANSLATIONREDUCER.${action.type}`)
            const payload = action.payload;
            return { ...payload, locale: getLocale(payload.language) };
        }
        default:
            return state;
    }
};

export default TranslationReducer;
