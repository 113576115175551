import { useContext, useMemo } from "react";
import useApi from "../../../../common/usings/use-api";
import AlertContext from "../../../../../contexts/alert/alert-context";
import AuthContext from "../../../../../contexts/auth/auth-context";
import useHelpers from '../../../../common/usings/use-helpers';
import TranslationsContext from "../../../../../contexts/translation/translation-context";
import { Severity } from "../../../../../constants";

const useExplorerContext = () => {

    const { t } = useContext(TranslationsContext);
    const { getData, postData, getError } = useApi();
    const { setAlert } = useContext(AlertContext);
    const { isAuthenticated, userId } = useContext(AuthContext);
    const { flattenTreeData } = useHelpers();

    const getDirectories = async (onGetDirectories) => isAuthenticated && await getData(`api/explorer/dirtree`)
        .then(response => onGetDirectories ? onGetDirectories(buildTreeData(response.data)) : buildTreeData(response.data))
        .catch((ex) => setAlert(getError(ex)));

    const canAppend = (row) => {
        const roleExpr = `,${userId},`;

        return row.ownerId === userId ||
            (
                row.isPublic &&
                (row.prohibitions || '').indexOf(roleExpr) === -1 &&
                (
                    !row.permissions || row.permissions.indexOf(roleExpr) >= 0
                )
            );
    };

    const buildTreeData = (data) => {
        console.log('BUILDING RETRIEVED  TREE DATA');
        const flattedData = flattenTreeData(data);
        const ids = flattedData.map(x => x.id);
        return {
            rows: flattedData,
            tree: data.map(row => buildTreeChild(row, ids))
        };
    };

    const buildTreeChild = (row, ids) => {

        let treeRow = {
            id: row.id.toString(),//aborist tree  requires string
            parentId: `${row.parentId}`,
            original: row,
            name: row.name,
            canMoveUp: ids.indexOf(row.id) >= 0,
            canManage: row.ownerId === userId,
            canAppend: canAppend(row),
            children: (row.children || []).map(x => buildTreeChild(x, ids))
        };

        row.treeRow = treeRow;
        return treeRow;
    };

    const getDirectoryContent = async (parentId) => {
        return await getData(`api/explorer/dircontent/${parentId}`)
            .then((response) => response.data)
            .catch((ex) => {
                const errorMessage = getError(ex);
                console.log(errorMessage);
                setAlert(errorMessage);
                return null;
            });
    };

    const getUsers = async (onGetUsers) => isAuthenticated && await getData("/api/v1/explorer/userlist")
        .then((response) => onGetUsers ? onGetUsers(response.data) : response.data)
        .catch((ex) => setAlert(getError(ex)));

    const getInitialData = async (onGetDirectories, onGetUsers) => await Promise.all([getDirectories(onGetDirectories), getUsers(onGetUsers)]);

    const changeItemPermissions = async (row, selectedUsers) => await postData(`/api/explorer/dirpermissions/${row.id}`, selectedUsers)
        .then(() => {
            row.permissions = selectedUsers.length ? `,${selectedUsers.toString()},` : null;
            return setAlert(t('DataUpdated'), Severity.success);
        }).catch((ex) => setAlert(getError(ex)));

    const changeItemProhibitions = async (row, selectedUsers) => await postData(`/api/explorer/dirprohibitions/${row.id}`, selectedUsers)
        .then(() => {
            row.prohibitions = selectedUsers.length ? `,${selectedUsers.toString()},` : null;
            return setAlert(t('DataUpdated'), Severity.success);
        }).catch((ex) => setAlert(getError(ex)));

    return useMemo(() => {
        return {
            getDirectories,
            getDirectoryContent,
            getInitialData,
            updatePermissions: changeItemPermissions,
            updateProhibitions: changeItemProhibitions,
        }
        // eslint-disable-next-line
    }, [isAuthenticated, userId]);
};

export default useExplorerContext;
