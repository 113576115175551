import React, { useContext, Suspense, useMemo, lazy } from 'react';

import AuthContext from '../../../contexts/auth/auth-context';
import Loader from '../components/loader/loader';

const LazyCommonLayout = lazy(() => import('./common-layout'));
const LazyAuthorizedLayout = lazy(() => import('../../authorized/layout/authorized-layout'));
const LazyPublicLayout = lazy(() => import('../../public/layout/public-layout'));

const LayoutChooser = () => {
    const { isAuthenticated, userId } = useContext(AuthContext);
    return useMemo(() =>
        <Suspense fallback={<Loader show={true} />}>
            <LazyCommonLayout>
                {isAuthenticated ? <LazyAuthorizedLayout /> : <LazyPublicLayout />}
            </LazyCommonLayout>
        </Suspense>,
        // eslint-disable-next-line
        [isAuthenticated, userId]
    );
};

export default LayoutChooser;


