import { useContext } from "react";
import { lib, mode, MD5 } from 'crypto-js';
import AES from 'crypto-js/aes';
import Base64 from 'crypto-js/enc-base64';
import Utf8 from 'crypto-js/enc-utf8';
import Pkcs7 from 'crypto-js/pad-pkcs7';

import useApi from "./use-api";
import { StorageKeys } from "../../../constants";
import TranslationsContext from "../../../contexts/translation/translation-context";

const doNothingFunc = () => { /*This is intentional*/ };

const useAuth = () => {

    const { getData, postData } = useApi();
    const { changeApiVersion } = useContext(TranslationsContext);

    const handleAuthResponse = (data) => {
        if (!data) {
            return null;
        }
        const storedVersion = localStorage.getItem(StorageKeys.ApiVersion);
        if (data.version !== storedVersion) {
            changeApiVersion(data.version);
        }

        setConsoleLogLevel(data.consoleToolLogLevel);

        return data;
    };

    const getEncodedAuthDataHash = (key) => {
        const hash = MD5(Base64.stringify(key)).toString();
        return encodeURIComponent(hash);
    }

    const getValidKey = async (aesKey) => {
        if (!aesKey) {
            return await createKey();
        }

        const keyHash = getEncodedAuthDataHash(aesKey);
        try {
            await getData(`/api/authentication/key?keyHash=${keyHash}`);
            return aesKey;
        } catch (ex) {
            return await createKey();
        };
    };

    const createKey = async () => {
        const key = lib.WordArray.random(32);
        const keyString = Base64.stringify(key);
        const result = await postData(`/api/authentication/key`, JSON.stringify(keyString));
        if (result.data !== keyString) {
            const error = "createKey() -> Data mistmach";
            throw new Error(error);
        }

        return key;
    };

    const getAuthData = async (key) => {
        const aesKey = await getValidKey(key);
        const dataResponse = await getData(`/api/authentication/authstatus`);
        const authStatus = handleAuthResponse({ ...dataResponse.data, aesKey: aesKey });
        return authStatus;
    };

    const checkRedirected = async (secret) => await getData(`/api/authentication/redirected?secret=${secret}`)
        .then((response) => handleAuthResponse(response.data))
        .finally(() => window.location.assign(window.location.pathname));

    const canCrypt = (value, aesKey, aesIv) => value && aesKey && aesIv;

    const decrypt = (encryptedBase64String, aesKey, aesIv) => {
        try {
            const cipherParams = lib.CipherParams.create({ ciphertext: Base64.parse(encryptedBase64String) });
            const dec = AES.decrypt(cipherParams, aesKey, { mode: mode.CBC, iv: aesIv, padding: Pkcs7 });
            return dec.toString(Utf8)
        }
        catch (err) {
            return '';
        }
    };

    const encrypt = (plainText, aesKey, aesIv) => {
        try {
            const enc = AES.encrypt(plainText, aesKey, { mode: mode.CBC, iv: aesIv, padding: Pkcs7 });
            return enc.toString();
        }
        catch (err) {
            return '';
        }
    }

    const aesEncrypt = (plainText, aesKey, aesIv) => canCrypt(plainText, aesKey, aesIv) && encrypt(plainText, aesKey, aesIv);
    const aesDecrypt = (encryptedBase64String, aesKey, aesIv) => canCrypt(encryptedBase64String, aesKey, aesIv) && decrypt(encryptedBase64String, aesKey, aesIv);

    const setConsoleLogLevel = (level) => {
        if (!level) {
            return;
        }

        switch (level) {
            case 5:
                console.log = doNothingFunc;
                console.table = doNothingFunc;
                console.group = doNothingFunc;
                console.debug = doNothingFunc;
                console.info = doNothingFunc;
                console.warn = doNothingFunc;
                console.error = doNothingFunc;
                break;
            case 4:
                console.log = doNothingFunc;
                console.table = doNothingFunc;
                console.group = doNothingFunc;
                console.debug = doNothingFunc;
                console.info = doNothingFunc;
                console.warn = doNothingFunc;
                break;
            case 3:
                console.log = doNothingFunc;
                console.table = doNothingFunc;
                console.group = doNothingFunc;
                console.debug = doNothingFunc;
                console.info = doNothingFunc;
                break;
            case 2:
                console.log = doNothingFunc;
                console.debug = doNothingFunc;
                break;
            case 1://"Debug"
            default://0: "All"
                break;
        }
    };

    const getRandomAesParam = (count) => {
        const value = lib.WordArray.random(count)
        const base64 = Base64.stringify(value);
        return {
            base64,
            value
        };
    };

    const redirectToReturnUrl = () => {
        console.log("USE-AUTH -> redirectToReturnUrl() ");
        window.location.href = new URL(window.location.href).searchParams.get('returnUrl') || "/";
    };

    return {
        aesEncrypt,
        aesDecrypt,
        checkRedirected,
        setConsoleLogLevel,
        getAuthData,
        getRandomAesParam,
        redirectToReturnUrl,
    };
};

export default useAuth;