import { StorageKeys, DefaultLang } from "../../../constants";

const useTranslationHelpers = () => {

    const getAvailableLanguage = (lang, languageKeys) => {
        if (!languageKeys?.length) {
            return lang;
        }

        const storedLanguage = localStorage.getItem(StorageKeys.Language);

        const culture = languageKeys.indexOf(lang) > -1
            ? lang
            : (languageKeys.indexOf(storedLanguage) > -1 ? storedLanguage : DefaultLang);

        if (culture !== lang) {
            localStorage.setItem(StorageKeys.Language, culture);
        }
        
        return culture;
    };

    const getTranslations = (culture, source) => {
        let obj = {};
        Object.keys(source).forEach(x => obj[x] = source[x][culture]);
        return obj;
    }

    const createLangTranslations = (lang, source) => {

        if (!Object.keys(source || {}).length) {
            return { culture: null, storedLanguageKeys: null, storedLangTranslations: null };
        }

        const languageKeys = Object.keys(source[Object.keys(source)[0]]);
        const culture = getAvailableLanguage(lang, languageKeys);
        const parsedTranslations = getTranslations(culture, source);
        return { culture, languageKeys, parsedTranslations };
    };


    return ({ createLangTranslations });
};

export default useTranslationHelpers;