import React, { useState, useRef, useEffect } from "react";
import useAuth from "../../scopes/common/usings/use-auth";
import AuthContext from "./auth-context";
import useApi from "../../scopes/common/usings/use-api";
import useHelpers from "../../scopes/common/usings/use-helpers";
import useInterval from "../../time-hooks/use-interval";
import { StorageKeys, DefaultRefreshInterval } from "../../constants";

const AuthProvider = ({ children }) => {
    const { getAuthData } = useAuth();
    const { postData } = useApi();
    const [state, setState] = useState();
    const stateRef = useRef(state);
    const { equals, getUnixSeconds } = useHelpers();

    const updateState = (data) => {
        if (data) {
            let changed = false;
            let current = state || {};
            Object.keys(data).forEach(key => {
                if (!equals(current[key], data[key])) {
                    current[key] = data[key];
                    changed = true;
                }
            });
            if (changed) {
                stateRef.current = current;
                setState(current);
            }
            return current;
        }

        return state;
    };

    const cycleAuthStateUpdate = async () => {
        localStorage.setItem(StorageKeys.LastStateUpdate, getUnixSeconds().toString());
        if (!state || !state.aesKey) {
            return;
        }
        const data = await updateAuthState(state.aesKey);
        return updateState(data);
    };

    useInterval(cycleAuthStateUpdate, state?.authUpdateTimeout || DefaultRefreshInterval);

    const getInitialState = async () => {
        const data = await getAuthData(undefined);
        return updateState(data);
    };

    useEffect(() => {
        if (!stateRef.current && !state) {
            stateRef.current = getInitialState();
        }
        // eslint-disable-next-line
    }, []);

    const updateAuthState = async (aesKey) => {
        const data = await getAuthData(aesKey);
        return updateState(data);
    };

    const signOut = async () => {
        await postData("/api/v1/authentication/logout");
        console.log('AUT-PROVIDER.signOut() executed,  updateAuthState()...');
        return await updateAuthState(null);
    };

    return (
        <AuthContext.Provider value={{
            version: (stateRef.current || state)?.version,
            isAuthenticated: (stateRef.current || state)?.isAuthenticated,
            isRequestLocal: (stateRef.current || state)?.isRequestLocal,
            isAuthorizedAdminIp: (stateRef.current || state)?.isAuthorizedAdminIp,
            authUpdateTimeout: (stateRef.current || state)?.authUpdateTimeout,
            clientIp: (stateRef.current || state)?.clientIp,
            swagger: (stateRef.current || state)?.swagger,
            aesKey: (stateRef.current || state)?.aesKey,
            signOut: signOut,
            updateAuthState: updateAuthState,
            userId: (stateRef.current || state)?.userId,
            userName: (stateRef.current || state)?.userName,
        }} >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
