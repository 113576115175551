import { useReducer } from "react";
import DialogContext from "./index";
import DialogContextReducer from "./dialog-context-reducer";
import { OPEN_DIALOG, CLOSE_DIALOG, UPDATE_DIALOG_DATA } from "./dialog-context-reducer";


const DialogContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(DialogContextReducer,
        {
            dialogCaptionKey: '',
            dialogOkButtonKey: '',
            dialogData: null,
            dialogContent: null,
            dialogOpen: false,
            setDialogOpen: [],
            setDialogClose: () => { },
            okDialogAction: () => { },
            updateDialogData: () => { },
        });

    const setDialogOpen = ({ captionKey, okKey, data, content, action }) => dispatch({ type: OPEN_DIALOG, payload: { captionKey, okKey, data, content, action } });
    const setDialogClose = () => dispatch({ type: CLOSE_DIALOG });
    const updateDialogData = (data) => dispatch({ type: UPDATE_DIALOG_DATA, payload: data });


    return (
        <DialogContext.Provider
            value={{
                dialogCaptionKey: state.dialogCaptionKey,
                dialogOkButtonKey: state.dialogOkButtonKey,
                dialogData: state.dialogData,
                dialogContent: state.dialogContent,
                dialogOpen: state.dialogOpen,
                setDialogOpen,
                setDialogClose,
                okDialogAction: state.okDialogAction,
                updateDialogData,
            }}
        >
            {children}
        </DialogContext.Provider >
    );
};

export default DialogContextProvider;