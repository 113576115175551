export const TOGGLE_THEME = 'TOGGLE_THEME';
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

export const Severity = { success: 'success', info: 'info', warning: 'warning', error: 'error' };

export const DefaultAlertDuration = 3000;
export const DefaultRefreshInterval = 120000;

export const DefaultLang = 'en';
export const DrawerSpacing = 40;
export const DefaultBaseCurrency = 'EUR';
export const EcbCurrencies = ['AUD', 'BGN', 'BRL', 'CAD', 'CHF', 'CNY', 'CZK', 'DKK', 'GBP', 'HKD', 'HUF', 'IDR', 'ILS', 'INR', 'ISK', 'JPY', 'KRW', 'MXN', 'MYR', 'NOK', 'NZD', 'PHP', 'PLN', 'RON', 'SEK', 'SGD', 'THB', 'TRY', 'USD', 'ZAR'];
export const DefaultCurrencies = ['USD', 'EUR', 'BTC', 'RUB', 'UAH', 'PLN', 'KZT', 'ILS', 'CNY', 'INR', 'JPY', 'KRW', 'SAR', 'BRL', 'ZAR', 'MXN', 'ARS'];
export const DefaultRateSources = [0, 1, 2];

export const StorageKeys = {
    Sid: 'SID',
    IsDarkTheme: 'is-dark-theme',
    ApiVersion: 'api-version',
    Language: 'language-key',
    Translations: 'translations',
    ClientIp: 'client-ip',
    CachedIpCount: 'cached-ip-count',
    RootElement: 'root',
    BaseCurrency: 'base-curency',
    LastCurrencies: 'last-curencies',
    ExRateSources: 'exchange-rate-sources',
    LastStateUpdate: "last-state-update",
    ExchangeRatesFrom: 'exchange-rates-from',
    ExplorerGridPageSize: 'explorer-grid-page-size',
};

export const Regexes = {
    Email: /\w+@\w+\.[a-zA-Z]{2,20}$/,
    Guid: /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i,
    GuidNonNil: /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i,
};

