import { MD5 } from 'crypto-js';

const sentenseCaseRegex = /(^\w{1}|\.\s*\w{1})/gi;

const useHelpers = () => {

    const toSentenseCase = (text) => text && text.replace(sentenseCaseRegex, (x) => x.toUpperCase());
    const random32String = () => MD5(Math.random().toString(36)).toString();
    const calculateRate = (rateToBase, baseToTargetBase) => (rateToBase === 0 || baseToTargetBase === 0) ? 0 : (1 / ((rateToBase || 1.0) * (baseToTargetBase || 1.0)));
    const isObject = (obj) => obj !== null && typeof obj === 'object' && !Array.isArray(obj);
    const equals = (prev, next) => isObject(prev) ? (JSON.stringify(prev) === JSON.stringify(next)) : (prev === next);
    const getUnixSeconds = (dateFrom, dateTo) => {

        dateFrom = (dateFrom && (typeof dateFrom.getMonth === 'function')) ? dateFrom : null;
        dateTo = (dateTo && (typeof dateTo.getMonth === 'function')) ? dateTo : null;

        if (!dateFrom && !dateTo) {
            return Math.round(new Date().getTime() / 1000);
        }

        if (dateFrom && !dateTo) {
            return Math.round((new Date() - dateFrom) / 1000);
        }

        if (!dateFrom && dateTo) {
            return Math.round(dateTo.getTime() / 1000);
        }

        return Math.round((dateTo - dateFrom) / 1000);
    };

    const flattenTreeData = (rows) => {
        let array = [];
        const recurse = (row) => {
            array.push(row);
            if (row.children) {
                row.children.forEach(recurse);
            }
        }
    
        rows.forEach(recurse);
        return array;
    };

    return {
        toSentenseCase,
        random32String,
        calculateRate,
        isObject,
        equals,
        getUnixSeconds,
        flattenTreeData,
    };
};


export default useHelpers;