import React, { useReducer } from "react";
import AlertContext from "./alert-context";
import AlertReducer from "./alert-reducer";
import { SET_ALERT, REMOVE_ALERT } from "../../constants";

const AlertProvider = ({ children }) => {

    const initialState = { alert: null };
    const [state, dispatch] = useReducer(AlertReducer, initialState);

    const setAlert = (msg, type, duration) => dispatch({ type: SET_ALERT, payload: { msg, type, duration } });
    const removeAlert = () => dispatch({ type: REMOVE_ALERT });

    return (
        <AlertContext.Provider
            value={{
                alert: state.alert,
                unread: state.unread,
                setAlert,
                removeAlert,
            }}
        >
            {children}
        </AlertContext.Provider>
    );
};

export default AlertProvider;
