export const DarkThemePalette = {
    isDark: true,
    palette: {
        mode: "dark",
        margins: {
            main: '#90caf9',
            light: '#e3f2fd',
            lowContrast: '#15317E',
            dark: '#001e3c',
            contrastText: 'rgba(0, 0, 0, 0.87)',
            shadowDark: '0px 2px 4px -1px rgba(0,0,0,0.7),0px 4px 5px 0px rgba(0,0,0,0.64),0px 1px 10px 0px rgba(0,0,0,0.62)',
            shadowLight: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
        },
        common: {
            black: '#000',
            white: '#fff',
        },
        primary: {
            main: '#90caf9',
            light: '#e3f2fd',
            dark: '#001e3c',
            contrastText: '#fff',
        },
        secondary: {
            main: '#ce93d8',
            light: '#f3e5f5',
            dark: '#ab47bc',
            contrastText: 'rgba(0, 0, 0, 0.87)',
        },
        error: {
            main: '#f44336',
            light: '#e57373',
            dark: '#d32f2f',
            contrastText: 'rgba(255, 255, 255, 1.0)',
        },
        warning: {
            main: '#ffa726',
            light: '#ffb74d',
            dark: '#f57c00',
            contrastText: 'rgba(255, 255, 255, 1.0)',
        },
        info: {
            main: '#29b6f6',
            light: '#4fc3f7',
            dark: '#0288d1',
            contrastText: 'rgba(255, 255, 255, 1.0)',
        },
        success: {
            main: '#66bb6a',
            light: '#81c784',
            dark: '#388e3c',
            contrastText: 'rgba(255, 255, 255, 1.0)',
        },
        grey: {
            50: '#fafafa',
            100: '#f5f5f5',
            200: '#eeeeee',
            300: '#e0e0e0',
            400: '#bdbdbd',
            500: '#9e9e9e',
            600: '#757575',
            700: '#616161',
            800: '#424242',
            900: '#212121',
            A100: '#f5f5f5',
            A200: '#eeeeee',
            A400: '#bdbdbd',
            A700: '#616161',
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
        text: {
            primary: '#fff',
            secondary: 'rgba(255, 255, 255, 0.7)',
            disabled: 'rgba(255, 255, 255, 0.5)',
            icon: 'rgba(255, 255, 255, 0.5)',
            accent: '#ff8c00',
            commonorange: '#ff8c00',
            commonblue: '#1565c0',
        },
        divider: 'rgba(255, 255, 255, 0.12)',
        background: {
            paper: '#001e3c',
            default: '#001e3c',
        },
        action: {
            active: '#fff',
            hover: 'rgba(255, 255, 255, 0.08)',
            hoverOpacity: 0.08,
            selected: 'rgba(255, 255, 255, 0.16)',
            selectedOpacity: 0.16,
            disabled: 'rgba(255, 255, 255, 0.3)',
            disabledBackground: 'rgba(255, 255, 255, 0.12)',
            disabledOpacity: 0.38,
            focus: 'rgba(255, 255, 255, 0.12)',
            focusOpacity: 0.12,
            activatedOpacity: 0.24,
        }
    }
};
