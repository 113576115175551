export const SET_DIRECTORIES = "SET_DIRECTORIES";
export const SET_PARENT_CONTENT = "SET_PARENT_CONTENT";
export const SET_PARENT_SELECTION = "SET_PARENT_SELECTION";
export const SET_PARENT_FILTER = "SET_PARENT_FILTER";
export const ON_ITEM_USERS_CLOSE = "ON_ITEM_USERS_CLOSE";
export const SET_USERS = "SET_USERS";
export const SET_ITEM_SELECTED_USERS = "SET_ITEM_SELECTED_USERS";
export const PERMISSIONS_TYPE = "Permissions";
export const PROHIBITIONS_TYPE = "Prohibitions";
export const OPEN_ITEM_PERMISSIONS = "OPEN_ITEM_PERMISSIONS";
export const OPEN_ITEM_PROHIBITIONS = "OPEN_ITEM_PROHIBITIONS";