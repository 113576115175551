import {
    SET_DIRECTORIES,
    SET_PARENT_CONTENT,
    SET_PARENT_SELECTION,
    SET_PARENT_FILTER,
    ON_ITEM_USERS_CLOSE, SET_USERS,
    SET_ITEM_SELECTED_USERS,
    PERMISSIONS_TYPE,
    PROHIBITIONS_TYPE,
    OPEN_ITEM_PERMISSIONS,
    OPEN_ITEM_PROHIBITIONS
} from './explorer-context-constants';

const ExplorerContextReducer = (state, action) => {
    switch (action.type) {
        case SET_DIRECTORIES:
            return { ...state, directories: action.payload?.tree, rows: action.payload?.rows };
        case SET_PARENT_CONTENT:
            return { ...state, content: action.payload };
        case SET_PARENT_SELECTION:
            return { ...state, selectedParent: action.payload, parentSelection: `${action.payload?.id}` };
        case SET_PARENT_FILTER:
            return { ...state, treeFilter: action.payload };
        case OPEN_ITEM_PERMISSIONS:
            const permissions = Array.from(action.payload.row?.permissions || '').filter(x => x !== ',').map(x => Number(x));
            return { ...state, openUsers: action.payload.openUsers, type: PERMISSIONS_TYPE, row: action.payload.row, selectedUsers: permissions };
        case OPEN_ITEM_PROHIBITIONS:
            const prohibitions = Array.from(action.payload.row?.prohibitions || '').filter(x => x !== ',').map(x => Number(x));
            return { ...state, openUsers: action.payload.openUsers, type: PROHIBITIONS_TYPE, row: action.payload.row, selectedUsers: prohibitions };
        case ON_ITEM_USERS_CLOSE:
            return { ...state, openUsers: false, type: '', row: null, selectedUsers: [] };
        case SET_USERS:
            return { ...state, users: action.payload };
        case SET_ITEM_SELECTED_USERS:
            return { ...state, selectedUsers: action.payload };
        default:
            return state;
    }
};

export default ExplorerContextReducer;