export const LightThemePalette = {
    isDark: false,
    palette: {
        mode: "light",
        margins: {
            main: '#1976d2',
            light: '#42a5f5',
            lowContrast: '#C2DFFF',
            dark: '#1565c0',
            contrastText: '#fff',
            shadowDark: '0px 2px 4px -1px rgba(0,0,0,0.7),0px 4px 5px 0px rgba(0,0,0,0.64),0px 1px 10px 0px rgba(0,0,0,0.62)',
            shadowLight: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
        },
        common: {
            black: '#000',
            white: '#fff',
        },
        primary: {
            main: '#1976d2',
            light: '#42a5f5',
            dark: '#1565c0',
            contrastText: '#fff',
        },
        secondary: {
            main: '#9c27b0',
            light: '#f3e5f5',
            dark: '#7b1fa2',
            contrastText: '#fff',
        },
        error: {
            main: '#d32f2f',
            light: '#ef5350',
            dark: '#c62828',
            contrastText: 'rgba(255, 255, 255, 1.0)',
        },
        warning: {
            main: '#ED6C02',
            light: '#ff9800',
            dark: '#e65100',
            contrastText: 'rgba(255, 255, 255, 1.0)',
        },
        info: {
            main: '#0288d1',
            light: '#19d2d2',
            dark: '#01579b',
            contrastText: 'rgba(255, 255, 255, 1.0)',
        },
        success: {
            main: '#2e7d32',
            light: '#4caf50',
            dark: '#1b5e20',
            contrastText: 'rgba(255, 255, 255, 1.0)',
        },
        grey: {
            50: '#fafafa',
            100: '#f5f5f5',
            200: '#eeeeee',
            300: '#e0e0e0',
            400: '#bdbdbd',
            500: '#9e9e9e',
            600: '#757575',
            700: '#616161',
            800: '#424242',
            900: '#212121',
            A100: '#f5f5f5',
            A200: '#eeeeee',
            A400: '#bdbdbd',
            A700: '#616161',
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
        text: {
            primary: 'rgba(0, 0, 0, 0.87)',
            secondary: 'rgba(0, 0, 0, 0.6)',
            disabled: 'rgba(0, 0, 0, 0.38)',
            accent: '#1565c0',
            commonorange: '#ff8c00',
            commonblue: '#1565c0',            
        },
        divider:
            'rgba(0, 0, 0, 0.12)',
        background: {
            paper: '#fff',
            default: '#fff',
        },
        action: {
            active: 'rgba(0, 0, 0, 0.54)',
            hover: 'rgba(0, 0, 0, 0.04)',
            hoverOpacity: 0.04,
            selected: 'rgba(0, 0, 0, 0.08)',
            selectedOpacity: 0.08,
            disabled: 'rgba(0, 0, 0, 0.26)',
            disabledBackground: 'rgba(0, 0, 0, 0.12)',
            disabledOpacity: 0.38,
            focus: 'rgba(0, 0, 0, 0.12)',
            focusOpacity: 0.12,
            activatedOpacity: 0.12,
        }
    }
};