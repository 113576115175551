import React from 'react';
import AppThemeProvider from './contexts/theme/theme-provider';
import AuthProvider from './contexts/auth/auth-provider';
import TranslationsProvider from './contexts/translation/translation-provider';
import AlertProvider from './contexts/alert/alert-provider';
import LayoutChooser from './scopes/common/layout/layout-chooser';
import './custom.css';
import { StorageKeys, DefaultRefreshInterval } from './constants';
import useHelpers from './scopes/common/usings/use-helpers';
import ExplorerContextProvider from './scopes/authorized/components/explorer/explorer-context/explorer-context-provider';
import DialogContextProvider from './contexts/dialog/dialog-context-provider';

const App = () => {

  const { getUnixSeconds } = useHelpers();
  const unixNowSeconds = getUnixSeconds();
  const lastRefreshUnixSeconds = Number(localStorage.getItem(StorageKeys.LastStateUpdate));
  if ((unixNowSeconds - lastRefreshUnixSeconds) > (DefaultRefreshInterval * 3)) {
    localStorage.setItem(StorageKeys.LastStateUpdate, unixNowSeconds.toString());
    window.location.reload();
  }

  return (

    <TranslationsProvider>
      <AppThemeProvider>
        <AuthProvider>
          <AlertProvider>
            <ExplorerContextProvider>
              <DialogContextProvider>
                <LayoutChooser />
              </DialogContextProvider>
            </ExplorerContextProvider>
          </AlertProvider>
        </AuthProvider>
      </AppThemeProvider>
    </TranslationsProvider>

  );
};

export default App;

