
import { TOGGLE_THEME, StorageKeys } from "../../constants";
import { LightThemePalette } from './light-theme-palette';
import { DarkThemePalette } from './dark-theme-palette';

const ThemeReducer = (state, action) => {
    if (action.type === TOGGLE_THEME) {
        localStorage.setItem(StorageKeys.IsDarkTheme, !state.theme.isDark);
        const root = document.getElementById(StorageKeys.RootElement);
        root.className = !state.theme.isDark ? 'dark' : 'light';

        return {
            ...state,
            theme: state.theme.isDark ? LightThemePalette : DarkThemePalette
        };
    } else {
        return state;
    }
};

export default ThemeReducer;