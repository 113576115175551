export const OPEN_DIALOG = 'OPEN_DIALOG';
export const CLOSE_DIALOG = 'CLOSE_DIALOG';
export const UPDATE_DIALOG_DATA = 'UPDATE_DIALOG_DATA';


const DialogContextReducer = (state, action) => {
    switch (action.type) {
        case OPEN_DIALOG:
            return {
                ...state,
                dialogOpen: true,
                dialogCaptionKey: action.payload.captionKey,
                dialogOkButtonKey: action.payload.okKey,
                dialogData: action.payload.data,
                dialogContent: action.payload.content,
                okDialogAction: action.payload.action,
            };
        case CLOSE_DIALOG:
            return {
                ...state,
                dialogOpen: false,
                dialogCaptionKey: '',
                dialogOkButtonKey: '',
                dialogData: null,
                dialogContent: null,
                okDialogAction: () => { },
            };
        case UPDATE_DIALOG_DATA:
            return { ...state, dialogData: action.payload };
        default:
            return state;
    }
};

export default DialogContextReducer;