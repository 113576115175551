import React, { useReducer } from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LightThemePalette } from './light-theme-palette';
import { DarkThemePalette } from './dark-theme-palette';
import ThemeContext from "./theme-context";
import ThemeReducer from "./theme-reducer";


import { TOGGLE_THEME, StorageKeys } from "../../constants";

const AppThemeProvider = ({ children }) => {

    const isDarkTheme = localStorage.getItem(StorageKeys.IsDarkTheme) !== "false";
    const root = document.getElementById(StorageKeys.RootElement);
    root.className = !isDarkTheme ? 'light' : 'dark';

    const initialState = {
        theme: isDarkTheme ? DarkThemePalette : LightThemePalette
    };

    const [state, dispatch] = useReducer(ThemeReducer, initialState);
    const toggleTheme = () => dispatch({ type: TOGGLE_THEME });

    return (
        <ThemeContext.Provider value={{ toggleTheme, theme: state.theme }} >
            <ThemeProvider theme={createTheme(state.theme)}>
                {children}
            </ThemeProvider>
        </ThemeContext.Provider>
    );
};

export default AppThemeProvider;
